import React from 'react';
import { Layout } from '../components';
const ReactMarkdown = require('react-markdown');

class ProductPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: props.pageContext.frontmatter,
            activeImage: props.pageContext.frontmatter.images[0],
            quantity: 1
        }
    }

    add = () => {
        const {quantity} = this.state;
        const newQuantity = quantity + 1;
        this.setState({quantity: newQuantity});
    }

    subtract = () => {
        const {quantity} = this.state;
        if(quantity - 1 >= 1) {
            this.setState({
                quantity: quantity - 1
            })
        }
    }

    setActiveImage = (newImage) => {
        this.setState({ activeImage: newImage });
    }
    
    render() {
        const {quantity, product, activeImage} = this.state;
        if(!product) return <div>Product not found.</div>
        return (
            <Layout {...this.props}>
                <div id="product">
                    <div id="wrapper">
                        <section id="main" className="wrapper">
                            <div className="inner">
                                {/* <div style={{maxWidth: '400px'}}>
                                    <Img fluid={product.image.fluid} />
                                </div> */}
                                <div className="row">
                                    <div className="column column-6">
                                        <div className="image-wrap main-image">
                                            <img src={activeImage} alt="" />
                                        </div>
                                        <div className="images d-flex align-items-center">
                                            {product.images.map((image, index) => (
                                                <div className="product-image-wrap" key={index} onClick={() => this.setActiveImage(image)}>
                                                    <div className={`product-image cursor-pointer ${image === activeImage ? 'active' : ''}`} style={{backgroundImage: `url(${image})`}} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="column column-6">
                                        <h1>{product.title}</h1>
                                        <div className="price">${product.price}</div>
                                        <div className="d-flex actions-row">
                                            <div className="d-flex align-items-center quantity-container">
                                                <div className="btn cursor-pointer" onClick={() => this.subtract()}>-</div>
                                                <div className="quantity">{quantity}</div>
                                                <div className="btn cursor-pointer" onClick={() => this.add()}>+</div>
                                            </div>
                                            <button
                                                className="snipcart-add-item"
                                                data-item-id={product.title}
                                                data-item-price={product.price}
                                                data-item-url={`/products/${product.slug}`}
                                                data-item-name={product.title}
                                                data-item-quantity={quantity}
                                            >
                                                Add to cart
                                            </button>
                                        </div>
                                        {product.description && (
                                            <ReactMarkdown source={product.description} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Layout>
        )
    }
}
    
// export const query = graphql`
// query($id: String!) {
//     contentfulProduct(id: { eq: $id }) {
//         id
//         title
//         slug
//         description
//         price
//         image {
//             fluid {
//                 base64 
//                 aspectRatio 
//                 src 
//                 srcSet 
//                 sizes 
//             }
//         }
//     }
// }
// `;
    
export default ProductPage;